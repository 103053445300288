// --- Variables (optional but good practice) ---
$card-width: 300px;
$card-height: 400px; // Or use aspect-ratio
$card-border-radius: 15px;
$flip-duration: 0.6s;
$primary-text-color: #333;
$secondary-text-color: #555;
$front-bg: #f8f8f8;
$back-bg: #e0e0e0; // Slightly different bg for back
$image-height: 180px; // Control image size

// --- Main Container for all cards ---
// --- Variables (optional but good practice) ---
$card-width: 300px;
$card-height: 400px; // Or use aspect-ratio
$card-border-radius: 15px;
$flip-duration: 0.6s;
$primary-text-color: #333;
$secondary-text-color: #555;
$front-bg: #f8f8f8;
$back-bg: #e0e0e0; // Slightly different bg for back
$image-height: 180px; // Control image size
$grid-gap: 30px; // Define gap for grid

// --- Main Container for all cards (Using Grid) ---
.activity-list-container {
  background: 
  radial-gradient(farthest-side at -33.33% 50%,#0000 52%,#00edff 54% 57%,#0000 59%) 0 calc(128px/2),
  radial-gradient(farthest-side at 50% 133.33%,#0000 52%,#00edff 54% 57%,#0000 59%) calc(128px/2) 0,
  radial-gradient(farthest-side at 133.33% 50%,#0000 52%,#00edff 54% 57%,#0000 59%),
  radial-gradient(farthest-side at 50% -33.33%,#0000 52%,#00edff 54% 57%,#0000 59%),
  #00abdf;
background-size: calc(128px/4.667) 128px,128px calc(128px/4.667);

  // width: 100%;
  display: grid; // Use Grid layout
  grid-template-columns: repeat(auto-fit, minmax($card-width, 1fr));
  gap: $grid-gap; // Spacing between grid items (rows and columns)
  padding: 1.5rem;
  justify-content: center; // Center the grid tracks if they don't fill the container width
  justify-items: center; // Center cards within their grid cell (horizontally)
  @media (max-width:800px){
    padding-top: 15px;
  }
}

.activity-card-container {
  width: $card-width;
  height: $card-height;
  perspective: 1000px;
  cursor: pointer;
  background-color: transparent;
  @media (max-width:800px){
    width: 97%;
  }
}

.activity-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform $flip-duration;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: $card-border-radius;

  &.is-flipped {
    transform: rotateY(180deg);
  }
}

.activity-card-face {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: $card-border-radius;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 20px;
  box-sizing: border-box;
  color: $primary-text-color;

  h2 {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 1.4em;
  }
}

.activity-card-front {
  background-color: $front-bg;

  .image-container {
    width: 100%;
    height: 80%;
    overflow: hidden;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  h2 {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.activity-card-back {
  background-color: $back-bg;
  transform: rotateY(180deg);
  justify-content: flex-start;
  padding: 20px;

  h2 {
     margin-bottom: 15px;
     font-size: 1.5rem;
    color: black;

  }

  .description {
    font-size: 1.3rem;
    color: black;
    text-align: left;
    margin-bottom: 20px;
    line-height: 1.4;
  }

  .price {
    margin-top: auto;
    font-size: 1.2rem;
    font-weight: bold;
    color: black;
  }
}